import * as React from 'react'
import DefaultLayout from './Default'
import { Link } from 'gatsby'

const meta = {
  title: 'Thank you',
  description: 'Thank you for contacting us.'
}

const ThankYou = ({ title, description }) => (
  <DefaultLayout {...meta} className="ctnr padding text-center richtext">
    <h1 className="h0">{title || meta.title}</h1>
    <p>{description || meta.description}</p>

    <p>Go <Link className="link" to="/">home</Link>.</p>
  </DefaultLayout>
)

export default ThankYou
